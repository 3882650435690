import React from "react";
import styles from "./ForgotPassword.module.css";

function LinkExpired() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <img
        style={{
          height: "100px",
          width: "100px",
        }}
        src="./logo.png"
      />

      <h1>Link Expired</h1>
    </div>
  );
}

export default LinkExpired;
