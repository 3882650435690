import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgotPassword from "./Pages/ForgotPassword";
import PageNotFound from "./Pages/PageNotFound";
import LinkExpired from "./Pages/LinkExpired";
import ChangedPassword from "./Pages/ChangedPassword";

function App() {
  return <BrowserRouter>
  <Routes>
    <Route path="/forgot-password/:token" element={<ForgotPassword/>} />
    <Route path="/forgot-password/link-expired" element={<LinkExpired/>} />
    <Route path="/forgot-password/changed-password" element={<ChangedPassword/>} />
    <Route path="*" element={<PageNotFound/>} />
  </Routes>
  </BrowserRouter>;
}

export default App;
